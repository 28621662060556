import React from "react";
import facebook from "../assets/facebook.png";
import twitter from "../assets/twitter.png";
import linkedin from "../assets/linkedin.png";

const Footer = () => {
    return (
        <footer>
            <div className="footerAddress">
                <span>
                    237, Dr.Subbarayan Road,
                    <br />
                    Tatabad, Coimbatore - 641012
                    <br /> TamilNadu, INDIA
                </span>
            </div>

            <h3>© 2020 By Enterot</h3>

            <ul>
                <li>
                    <a href="#facebook">
                        <img src={facebook} alt="Facebook" />
                    </a>
                </li>
                <li>
                    <a href="#twitter">
                        <img src={twitter} alt="Twoitter" />
                    </a>
                </li>
                <li>
                    <a href="#linkedin">
                        <img src={linkedin} alt="LinkedIn" />
                    </a>
                </li>
            </ul>
        </footer>
    );
};

export default Footer;
