import React from "react";
import Nav from "./nav";

const Header = () => {
    return (
        <header>
            <Nav />

            {/* <div className="head"><h1>EnterOT</h1></div> */}
        </header>
    );
};

export default Header;
