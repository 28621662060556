import React from "react";
import enterot from "../assets/enterot2.jpeg";

const Nav = () => {
    return (
        <nav>
            <ul>
                <li className="logo">
                    <img style={{ height: "100%" }} src={enterot} />
                </li>
            </ul>
            <ul className="navLinks">
                {/* <li>
                    <a href="#">About</a>
                </li> */}
                <li>
                    <a href="#WhoWeAre">Who we are</a>
                </li>
                <li>
                    <a href="#WhatWeDo">What we do</a>
                </li>
                <li>
                    <a href="#ReachUs">Reach us</a>
                </li>
            </ul>
        </nav>
    );
};

export default Nav;
