import React from "react";

import erpIcon from "../assets/erp1.svg";
import software from "../assets/software.svg";
import appdev from "../assets/appdev.svg";

import tallyPrime from "../assets/tallyPrime4.png";
import tallyPartner from "../assets/tallyPartner.png";
import enterLogo from "../assets/enterLogo.png";

const Main = () => {
    return (
        <main>
            <section id="WhoWeAre" className="intro">
                <h2>Who we are</h2>
                <div>
                    <p>
                        We at ENTER, specialize in IT consulting & implementation services. ENTER was established in 2004 from the Manchester of South
                        India, the Coimbatore with a vision to add value to business customers through technology & make them competitive & realize
                        the business potential in the global market.
                        <br /> <br /> Our portfolio of services ranges from IT consulting, staffing to implementation & support services. We provide
                        technology solutions & services to more than 250+ SMBs & large Enterprises in India. ENTER is a Tally Solutions certified
                        partner with a team of functional & technical experts.
                        <br /> <br /> Our client portfolio includes companies from Manufacturing, Supply Chain, Retail, Travel & Medical Equipment.
                        Enterot division, specialize in providing software solutions & services to SMBs & large enterprises. We have flexible
                        engagement models with our customers, be it turnkey solution or a staff augmentation model.
                    </p>
                </div>

                <br />
                <br />
                <div
                    className="enterTally"
                    // style={{ boxSizing: "border-box", boxShadow: "0 0 10px 2px #fcc200", padding: "0 20px ", margin: "40px 0" }}
                >
                    <br />
                    <br />

                    <div className="enterTallyImagesWrap">
                        <img className="enterTallyImages" src={enterLogo} alt="Enter" />
                        <span className="enterTallyImagesEmpty"></span>
                        <img className="enterTallyImages" src={tallyPartner} alt="Tally Solutions" />
                    </div>
                    <p>
                        <b>ENTER is a Tally Solutions certified partner with a team of functional & technical experts</b>
                    </p>
                </div>
            </section>

            {/* <section></section> */}

            {/* <div >What we do</div> */}

            <section id="WhatWeDo" className="intro">
                <h2>What we do</h2>
                {/* <div className="whoWeAre">
                    <img className="whoWeAreImg" src={axes} />
                    <p>
                        <h3 style={{ padding: 0, margin: 0 }}>Axes</h3>
                        <br />
                        “By really listening to our technology needs, Enterot provided solutions that fit our unique business model. Their ongoing
                        support and services have been instrumental to our company’s growth.”
                    </p>
                </div>

                <div className="whoWeAre">
                    <img className="whoWeAreImg" src={volve} />
                    <p>
                        <h3 style={{ padding: 0, margin: 0 }}>Volve</h3>
                        <br />
                        “Our company has confidently chosen Enterot for our IT needs over many years. They always make sure we’re ahead of the
                        technology curve, and are also very enjoyable to work with.”
                    </p>
                </div>

                <div className="whoWeAre">
                    <img className="whoWeAreImg" src={sovix} />
                    <p>
                        <h3 style={{ padding: 0, margin: 0 }}>Sovix</h3>
                        <br />
                        “Enterot transformed our company by taking us mobile and implementing cloud solutions, which gave our employees the
                        flexibility they needed to work most efficiently.”
                    </p>
                </div> */}
            </section>

            <div>
                <div className="services">
                    <div className="service-one">
                        <img src={software} alt="Software" />
                        <p className="service-title">Custom Software Development</p>
                        <p className="service-info">
                            Enabling your workforce with top-notch technologies isn’t just important, but imperative for business success. <br />
                            <br />
                            This customizable solution allows your team to work seamlessly and collaboratively in a protected space. <br /> <br />
                            No matter what IT services you need, Enterot will be there to support you every step of the way.
                        </p>
                    </div>
                    <div className="service-two">
                        <img src={erpIcon} alt="ERP" />

                        <p className="service-title">ERP Implementation</p>
                        <p className="service-info">
                            Our IT solutions carve the path for organizations to modernize infrastructure and optimize workflow like never before.
                            <br /> <br />
                            This service provides a wide variety of technological capabilities to boost efficiencies, enhance security and allow
                            infinite scalability for future growth. <br /> <br />
                            With this resource and our expertise, we’ll help maximize your organization’s productivity.
                        </p>
                    </div>
                    <div className="service-four">
                        <a
                            href="https://tallysolutions.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "none", color: "gray" }}
                        >
                            <img src={tallyPrime} style={{ width: "30%" }} alt="Tally Prime" />

                            <p className="service-title">Business Management Software</p>
                            <p className="service-info">
                                We believe in the power of technology to make business owners efficient, empowered and happier, so they can focus on
                                what matters most for their business.
                                <br />
                                <br /> We design our products to focus on just that – to make our products work for you, and not the other way around.
                                TallyPrime takes this to a new level, making your start to automation, or your switch to Tally simpler than ever
                                before.
                                <br />
                                <br />
                                You can now discover the product much more easily and make the product do more for you, without learning anything new.
                                There is greater flexibility as the product adapts to your business and your way of working. And the transformed look
                                and feel will only make you love the product even more.
                            </p>
                        </a>
                    </div>
                    <div className="service-three">
                        <img src={appdev} alt="App Dev" />

                        <p className="service-title">App Development</p>
                        <p className="service-info">
                            In today’s hyperconnected world, organizations are challenged in more ways than ever to stay ahead of the curve. <br />{" "}
                            <br /> This comprehensive service is designed to fit your unique needs without disrupting productivity or workflow. <br />{" "}
                            <br /> With our end-to-end solutions, Enterot is sure to help your organization succeed.
                        </p>
                    </div>
                </div>
            </div>

            <section id="ReachUs" className="intro">
                <h2>Reach us</h2>
                <div>
                    <br />
                    <p>
                        <tr>
                            <td>
                                <b>Email</b>
                            </td>
                            <td>info@enterot.com</td>
                        </tr>
                        {/* <tr>
                            <td>
                                <b>Phone</b>
                            </td>
                        </tr> */}
                        <tr>
                            <td>
                                <b>Direct</b>
                            </td>
                            <td>0422 42112347</td>
                        </tr>
                        <tr>
                            <td>
                                <b>Mobile</b>
                            </td>
                            <td>+91-99444-12342, +91-98941-12342</td>
                        </tr>
                        <tr>
                            <td>
                                <b>Address</b>
                            </td>
                            <td>
                                #237, Dr.Subbarayan Road,
                                <br />
                                Tatabad, Coimbatore - 641012
                                <br />
                                Tamil Nadu, INDIA
                            </td>
                        </tr>
                    </p>
                    <br /> <br />
                </div>
                <div className="locationMap">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7832.4062430837275!2d76.95187117692437!3d11.023381568616868!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba858fa2f7ea73d%3A0xce7ac4db7da50fa2!2sSivananda%20Colony%2C%20Tatabad%2C%20Coimbatore%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1605529525553!5m2!1sen!2sin"
                        frameborder="0"
                        style={{
                            border: "1px solid #002061",
                            // color: '#fcc200',
                            /* color: #002061; */
                            width: "100%",
                            minHeight: "400px",
                            maxHeight: "700px",
                        }}
                        alt="Location"
                        allowfullscreen=""
                        aria-hidden="false"
                        tabindex="0"
                    ></iframe>
                </div>
            </section>
        </main>
    );
};

export default Main;
